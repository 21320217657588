import React from "react";
import { Card, Box, Button, Typography } from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import { useNavigate } from "react-router-dom";
// import { MemberApi } from "../api/MemberApi";
// import { useSelector, useDispatch } from "react-redux";
// import { memberID, updateAuthState } from "../reducers/userSlice";

export default function EventCard({ event, hideActions = false }) {
  // const lang = getLang();
  const navigate = useNavigate();

  const eventName = localizedName(event, "name");

  const dateRange =
    timeToDateStr(event.start_at) + " - " + timeToDateStr(event.end_at);

  const hasEnroll = event.member_info !== null;

  let actionButton;

  const onDashboard = (e) => {
    const getCurrentSlot = () => {
      let { timeslotList } = event;

      if (timeslotList.length === 0) return 7;
      let currentTime = new Date().getHours();
      //   console.log(timeslotList);
      let currentSlot = timeslotList.find((_t) => {
        if ("break_time" in _t && _t.break_time === true) return false;
        const [startHour, startMin] = _t.start_at.split(":");
        const [endHour, endMin] = _t.end_at.split(":");

        return (
          parseInt(startHour) <= currentTime && parseInt(endHour) >= currentTime
        );
      });
      console.log(currentSlot);
      if (currentSlot !== undefined) return currentSlot.id;

      return 7; // the last slot
      //console.log(currentTime);
    };

    const currentSlot = getCurrentSlot(); // calculate from event data
    navigate(`/dashboard/${event.id}?timeslot=${currentSlot}`);
  };

  const onLeaderBoard = (e) => {
    console.debug("onLeaderboard");
    navigate(`/overall-dashboard/${event.id}`);
  };

  const onDataInput = (e) => {
    // console.debug("onDataInput");
    const options = {
      state: { event },
    };
    navigate(`/skill-list/${event.id}`, options);
  };

  const onImportTanita = (e) => {
    console.debug("onImportTanita");
  };

  //   actionButton = <></>;

  return (
    <Card variant="outlined" sx={{ p: 2, mb: 3 }}>
      <Box>
        <Typography
          level="h2"
          sx={{ color: "white.main", fontSize: "1.3rem", mb: 0.5 }}
        >
          {eventName}
        </Typography>
      </Box>
      <Box>活動日期：{dateRange}</Box>

      {hideActions === false && (
        <>
          <Box mt={2} />
          <Box direction="row" gap={2} display="flex">
            {/* <Button variant="contained" onClick={onDashboard}>
              今天活動排行榜
            </Button> */}
            <Button variant="contained" onClick={onLeaderBoard}>
              活動總排行榜
            </Button>
            {/* <Button variant="contained" onClick={onLeaderboard}>
              Leaderboard
            </Button> */}
            <Button variant="contained" color="secondary" onClick={onDataInput}>
              活動數據輸入
            </Button>
            {/* <Button variant="contained" onClick={onImportTanita}>
              Import Tanita Data
            </Button> */}
          </Box>
        </>
      )}
    </Card>
  );
}
