import {
    Container,
    Typography,
    Box,

} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import MemberSkillInputForm from "../../components/app/MemberSkillInputForm";
import MockData from "../../helper/MockData";
import SkillLabel from "../../components/app/SkillLabel";
import { or } from "numeric";

export default function TestMemberSkillInputForm() {
    const { t } = useTranslation();

    let lang = getLang();
    const skill = MockData.getSkillInfoList()[0];
    // skill.code = "vjump";
    // skill.name_zh = skill.code = "handgrip";
    skill.name_zh = skill.code = "football";
    let eventID = 1;
    let orgID = 2;

    return (
        <Container maxWidth="lg">
            <SkillLabel skill={skill}/>
            <Box mt={2} />

            <MemberSkillInputForm skill={skill} orgID={orgID} eventID={eventID} />

        </Container>
    )
}
