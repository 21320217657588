import { Box, Grid, IconButton, Button } from "@mui/material";
// Component
import OverallSportDisplay from "../components/leaderBoard/OverallSportDisplay";
import Footer from "../components/leaderBoard/Footer";
import { Carousel } from "react-responsive-carousel";
import MosaicSportDisplay from "../components/leaderBoard/MosaicSportDisplay/MosaicSportDisplay";
// import Mock from "../components/leaderBoard/SportDisplay/mock.json";
// Icon

import SvgToHome from "../components/icon/SvgToHome.jsx";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";

import { AdminApi } from "../api/AdminApi";
import { getCurrentDateStr } from "../helper/StringHelper"; 
const TODAY = getCurrentDateStr(); // new Date().toISOString().split("T")[0];
// console.debug("TODAY: ", TODAY);
// const TODAY = new Date().toISOString().split("T")[0];
const CarouselProps = {
  showThumbs: false,
  autoPlay: true,
  infiniteLoop: true,
  showIndicators: false,
  showStatus: false,
  showArrows: false,
  // interval: 60000,
  interval: 4000,
  emulateTouch: true,
};

export default function Page() {
  const navigate = useNavigate();

  const handleBackHome = (e) => {
    navigate("/");
  };

  const { eventId } = useParams();
  let [searchParams] = useSearchParams();
  // Get event id from url

  useEffect(() => {
    let date = searchParams.get("date") ? searchParams.get("date") : TODAY;

    AdminApi.loadEventOverallLeaderboardCallback(eventId, date, (data) => {
      // console.log(data);
      setLeaderData(data);
    });
    return;
  }, []);

  const [leaderData, setLeaderData] = useState([]);

  // forceUpdate(n => !n)

  return (
    <Box position={"relative"} overflow="hidden">
      <Box position={"absolute"} left={10} top={10} zIndex={10}>
        <IconButton onClick={() => handleBackHome()}>
          <SvgToHome />
        </IconButton>
      </Box>

      {/* <Footer /> */}

      <Grid container direction="row" alignItems={"center"}>
        <Grid item xs={12}>
          <Box>
            <Carousel {...CarouselProps}>
              {leaderData.map((_sportLeader, index) => (
                console.log("_sportLeader: ", _sportLeader),
                <MosaicSportDisplay
                  leaderboard={_sportLeader}
                  eventID={eventId}
                  date={TODAY}
                  key={`carousel-${_sportLeader.skill_code}-${index}`}
                />
              ))}
            </Carousel>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
