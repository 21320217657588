import axios, {
  getDataRequest,
  postDataRequest,
  // formatQueryResponse,
} from "../helper/AxiosHelper";
import ApiHelper from "./ApiHelper";

const login = async (account, password) => {
  const data = {
    account: account,
    password: password,
  };

  const response = await axios.post("/admin/login", data);

  return ApiHelper.processResponse(response);
};

const logout = async (email, password) => {
  const data = {};

  const response = await axios.post("/admin/logout", data);

  return ApiHelper.processResponse(response);
};

const checkSkillInput = async (eventID, qrCode, skill, score) => {
  const data = {
    event_id: eventID,
    qrcode_id: qrCode,
    skill_code: skill,
    skill_score: score,
  };

  const url = `/admin/checkSkillInput`;

  const response = await axios.post(url, data);

  return ApiHelper.processResponse(response);
};

const submitScoreQRCode = async (eventID, qrCode, skillCode, skillScore) => {
  const data = {
    event_id: eventID,
    qrcode_id: qrCode,
    skill_code: skillCode,
    skill_score: skillScore,
  };

  const url = `/admin/submitScoreQR`;

  const response = await axios.post(url, data);

  return ApiHelper.processResponse(response);
};

// Depm
const submitScore = async (memberID, eventID, skillCode, skillScore) => {
  const data = {
    member_id: memberID,
    event_id: eventID,
    skill_code: skillCode,
    skill_score: skillScore,
  };

  const url = `/admin/submitScore`;

  const response = await axios.post(url, data);

  return ApiHelper.processResponse(response);
};

const loadEventLeaderboardCallback = async (
  eventID,
  date,
  timeslotID,
  callback
) => {
  console.debug("loadEventLeaderboardCallback: ", eventID, date, timeslotID);

  const result = await loadEventLeaderboard(eventID, date, timeslotID);

  console.debug("loadEventLeaderboardCallback: result: ", result);

  if (callback) {
    console.debug("loadEventLeaderboardCallback: result: ", result);
    if (result == null || result === undefined) {
      console.error("invalid result");
      return;
    }
    callback(result);
  }
};

const loadEventLeaderboard = async (
  eventID,
  date,
  timeslotID,
  showDetail = false
) => {
  const url = `/admin/eventLeaderboard/${eventID}?date=${date}&timeslot_id=${timeslotID}&show_detail=${showDetail}`;
  const data = {};
  console.log("loadEventLeaderboard: ", url);

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};

const loadEventOverallLeaderboardCallback = async (eventID, date, callback) => {
  const result = await loadEventOverallLeaderboard(eventID, date);
  if (callback) {
    console.debug("loadEventOverallLeaderboard: result: ", result);
    if (result == null || result === undefined) {
      console.error("invalid result");
      return;
    }
    callback(result);
  }
};

const loadEventOverallLeaderboard = async (eventID, date) => {
  const url = `/admin/eventOverallLeaderboard/${eventID}?date=${date}`;
  const data = {};

  console.log("loadEventOverallLeaderboard: ", url);
  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};

const loadSingleEventLeaderboardCallback = async (
  eventID,
  date,
  timeslotID,
  skillCode,
  callback = () => { }
) => {
  const result = await loadSingleEventLeaderboard(
    eventID,
    date,
    timeslotID,
    skillCode
  );

  if (callback === undefined) {
    console.warn("loadSingleEventLeaderboardCallback: callback is undefined");
    return;
  }

  if (result == null || result === undefined || result.length === 0) {
    console.debug("loadSingleEventLeaderboardCallback: no result");
    callback(null);
    return;
  }

  callback(result[0]);
  return result[0];
};

const loadSingleEventLeaderboard = async (
  eventID,
  date,
  timeslotID,
  skillCode
) => {
  const url = `/admin/eventLeaderboard/${eventID}?skill=${skillCode}&date=${date}&timeslot_id=${timeslotID}`;
  const data = {};

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};

const loadSingleEventOverallLeaderboardCallback = async (
  eventID,
  date,
  queryCode,
  callback
) => {
  const result = await loadSingleEventOverallLeaderboard(
    eventID,
    date,
    queryCode
  );
  if (callback) {
    console.debug("loadEventOverallLeaderboard: result: ", result);
    if (result == null || result === undefined) {
      console.error("invalid result");
      return;
    }
    callback(result);
  }

  return result;
};

const loadSingleEventOverallLeaderboard = async (eventID, date, queryCode) => {
  const url = `/admin/singleEventOverallLeaderboard/${eventID}?date=${date}&query_code=${queryCode}`;
  const data = {};

  const response = await axios.get(url, data);

  return ApiHelper.processResponse(response);
};

const previewHealthData = async (eventID, fileObject) => {
  if (fileObject === null || fileObject === undefined) {
    throw new Error("File is not selected");
  }

  if (fileObject.name.endsWith(".csv") === false) {
    throw new Error("請上載 CSV 檔案");
  }

  const url = `/admin/previewHealthData`;

  const formData = new FormData();
  formData.append("event_id", eventID);
  formData.append("file", fileObject);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const response = await axios.post(url, formData, options);

  return ApiHelper.processResponse(response);
};

const importHealthData = async (eventID, fileObject) => {
  if (fileObject === null || fileObject === undefined) {
    throw new Error("File is not selected");
  }

  if (fileObject.name.endsWith(".csv") === false) {
    throw new Error("請上載 CSV 檔案");
  }

  const url = `/admin/importHealthData`;

  const formData = new FormData();
  formData.append("event_id", eventID);
  formData.append("file", fileObject);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const response = await axios.post(url, formData, options);

  return ApiHelper.processResponse(response);
};

// const
// const importHealthData = async (eventID, fileObject) => {

/// --- Get Organization
const getOrgnizationDetailCallback = (orgId, callback) => {
  const url = `/organization/show/${orgId}`;
  getDataRequest(url, (isSuccess, _data) => {
    if (!isSuccess) {
      let msg = _data;
      callback(false, msg, null); // _data is error message
      return;
    }

    callback(true, "", _data);
    // queryResultSetter(() => formatQueryResponse(_data));
    // backdropSetter(() => false);
  });

  // let urlWithQuery = `${fetchDataConfig.url}?query=${JSON.stringify(query)}`;
  // if ("extraQuery" in fetchDataConfig) {
  //   fetchDataConfig.extraQuery.forEach((extraQueryParma) => {
  //     if (urlParma[extraQueryParma.field] === undefined) return;
  //     urlWithQuery += `&${extraQueryParma.key}=${
  //       urlParma[extraQueryParma.field]
  //     }`;
  //   });
  // }
  // getDataRequest(urlWithQuery, (isSuccess, _data) => {
  //   if (!isSuccess) {
  //     return false;
  //   }
  //   queryResultSetter(() => formatQueryResponse(_data));
  //   backdropSetter(() => false);
  // });
  //
  //getDataRequest(`organization/show/${orgId}`, (isSuccess, data) => {
  // const url = `/admin/getOrgnizationID/${orgId}`;
  // const data = {};

  // const response = await axios.get(url, data);

  // return ApiHelper.processResponse(response);
};

const getOrgnizationListCallback = (callback) => {
  const url = `/organization`;
  getDataRequest(url, (isSuccess, _data) => {
    if (!isSuccess) {
      let msg = _data;
      callback(false, msg, null); // _data is error message
      return;
    }

    callback(true, "", _data);
    // queryResultSetter(() => formatQueryResponse(_data));
    // backdropSetter(() => false);
  });
};

const batchInsertSkillScore = async (formData, callback) => {
  const response = await postDataRequest(
    "/admin/batchSubmitScore",
    formData,
    (isSuccess, _data) => {
      if (!isSuccess) {
        let msg = _data;
        callback(false, msg, null); // _data is error message
        return;
      }

      callback(true);
      // queryResultSetter(() => formatQueryResponse(_data));
      // backdropSetter(() => false);
    }
  );

  return ApiHelper.processResponse(response);
};

const getEquCateListCallback = (callback) => {
  const url = `/equipmentCategory`;
  getDataRequest(url, (isSuccess, _data) => {
    if (!isSuccess) {
      let msg = _data;
      callback(false, msg, null); // _data is error message
      return;
    }

    callback(true, "", _data);
    // queryResultSetter(() => formatQueryResponse(_data));
    // backdropSetter(() => false);
  });
};

const insertEquipmentData = async (formData, callback) => {
  const response = await postDataRequest(
    "/equipment/create",
    formData,
    (isSuccess, _data) => {
      if (!isSuccess) {
        let msg = _data;
        callback(false, msg, null); // _data is error message
        return;
      }

      callback(true);
      // queryResultSetter(() => formatQueryResponse(_data));
      // backdropSetter(() => false);
    }
  );

  return ApiHelper.processResponse(response);
};

const updateEquipmentData = async (equId, formData, callback) => {
  const response = await postDataRequest(
    "/equipment/update/" + equId,
    formData,
    (isSuccess, _data) => {
      if (!isSuccess) {
        let msg = _data;
        callback(false, msg, null); // _data is error message
        return;
      }

      callback(true);
      // queryResultSetter(() => formatQueryResponse(_data));
      // backdropSetter(() => false);
    }
  );

  return ApiHelper.processResponse(response);
};



export const AdminApi = {
  login,
  logout,
  previewHealthData,
  importHealthData,
  getOrgnizationListCallback,
  getOrgnizationDetailCallback,
  loadEventLeaderboard,
  loadEventLeaderboardCallback,
  loadSingleEventLeaderboardCallback,
  loadEventOverallLeaderboard,
  loadEventOverallLeaderboardCallback,
  loadSingleEventOverallLeaderboard,
  loadSingleEventOverallLeaderboardCallback,
  checkSkillInput,
  submitScore,
  submitScoreQRCode,
  batchInsertSkillScore,
  getEquCateListCallback,
  insertEquipmentData,
  updateEquipmentData,
};
