import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Container, Typography } from '@mui/material';
import MemberQueryCardSection from '../../sections/_dashboard/MemberQueryCardSection';
import {
  getlocalStorageUserObject,
} from "../../helper/LocalStorageHelper";

// ----------------------------------------------------------------------

export default function UserMemberQRPage() {
  const user = getlocalStorageUserObject();

  const currentOrgID = user ? user.organization_id : 0;

  return (
    <>
      <title> User Member QR Checking </title>


      <Container maxWidth="xl">
        <Stack direction="column">
          
          <Typography variant="h4" sx={{ mb: 5 }}>
            Member QR Checking
          </Typography>
          <MemberQueryCardSection selectedOrgId={currentOrgID} />
        </Stack>

      </Container>
    </>
  );
}
