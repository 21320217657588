import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import { Group } from "@mui/icons-material";
import GroupMemberSelection from "../../components/GroupMemberSelection/GroupMemberSelection";
import MockData from "../../helper/MockData";

export default function TestGroupMemberSelection() {
    const { t } = useTranslation();
    const [member, setMember] = React.useState(null); // [member, setMember

    let lang = getLang();

    let memberList = MockData.getMemberList();

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Member Selection</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <GroupMemberSelection memberList={memberList} onSelectMember={
                    (member) => {
                        console.log("member: ", member);
                        setMember(member);
                    }                    
                } />
                <Typography variant="h5">Selected Member</Typography>
                <Box>
                    <p>Member: {member ? member.display_name : "null"}</p>
                </Box>
            </Stack>
        </Container>
    )
}
