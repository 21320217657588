import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import palette from "../theme/palette";
import { Translation } from "react-i18next";

const ReactSwal = withReactContent(Swal);

export default ReactSwal.mixin({
  confirmButtonColor: palette.secondary.main,
  cancelButtonColor: palette.secondary.main,
  focusCancel: true,
  confirmButtonText: <Translation>{(t) => t("layout.ok")}</Translation>,
  cancelButtonText: <Translation>{(t) => t("layout.cancel")}</Translation>,
});
