import { Box, Grid, Typography, Stack, Container } from "@mui/material";
import MosaicSportDisplayItem from "../MosaicSportDisplayItem";

import leaderBoardBg_1 from "../../../public/leaderBoardBg_1.png";
import leaderBoardBg_2 from "../../../public/leaderBoardBg_2.png";
import leaderBoardBg_3 from "../../../public/leaderBoardBg_3.png";
import leaderBoardBg_4 from "../../../public/leaderBoardBg_4.png";
import { useEffect } from "react";
import { AdminApi } from "../../../api/AdminApi";
import { useState } from "react";

const MAIN_FONT = "Noto Sans TC";

const BackgroundMap = {
  blue: leaderBoardBg_1,
  orange: leaderBoardBg_2,
  purple: leaderBoardBg_3,
  green: leaderBoardBg_4,
};

const getBackgroundURL = (color) => {
  const colorIDMap = {
    blue: 1,
    orange: 2,
    purple: 3,
  };

  const colorID = colorIDMap[color] || 1;

  // /assets/bg/background-01.webp
  return `/assets/bg/background-0${colorID}.webp`;
  // // return `url("assets/bg/background-0${colorID}.webp")`;
  // // return `url("/assets/bg/background-01.webp")`;
  // return leaderBoardBg_1; // "../../../public/leaderBoardBg_1.png";
}


export default function MosaicSportDisplay({ leaderboard, date, eventID, overall }) {
  const [localLeaderboard, setLocalSportLeader] = useState(leaderboard);




  const NothingDisplay = () => {
    return (
      <Box
        sx={{
          border: "3px solid #eee",
          borderRadius: "10px",
          boxShadow: " 0 3px 10px  #9ecaed;",
        }}
        my={4}
      >
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          py={4}
          px={4}
        >
          <Grid item xs={12}>
            <Typography variant="h2" color={"white"}>
              未有資料
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  useEffect(() => {
    setLocalSportLeader((pre) => leaderboard);

    // setnIntervId(
    const interval = setInterval(() => {
      SportLeaderUpdate();
    }, process.env.REACT_APP_UPDATE_INTERVAL || 10000);

    return () => clearInterval(interval);

    // );
  }, [leaderboard]);

  const SportLeaderUpdate = async () => {
    if (!("timeslot_id" in leaderboard)) return;
    let result = await AdminApi.loadSingleEventOverallLeaderboardCallback(
      eventID,
      date,
      leaderboard.query_code
    );
    setLocalSportLeader(result);
  };

  const bgUrl = getBackgroundURL(localLeaderboard?.color || "blue");

  console.debug("bgUrl: ", bgUrl);

  const top3 = localLeaderboard?.result?.slice(0, 3) || [];
  const rest = localLeaderboard?.result?.slice(3) || [];

  const top3List = top3.map((content, index) => {
    return (
      <MosaicSportDisplayItem key={`rand-${index}`}
        content={content} 
        itemSize="big" />
    );
  }
  );

  const restList = rest.map((content, index) => {
    return (
      <MosaicSportDisplayItem key={`rand-${index}`}
        content={content}
        itemSize="normal" />
    );
  }
  );

  // {localLeaderboard.result.map((_r, index) => (
  //   <Grid item key={index} sx={{ width: "auto" }}>
  //     <MosaicSportDisplayItem key={`rand-${index}`} content={_r} overall />
  //   </Grid>
  // ))}

  return (
    <Box
      align="center"
      pt={6}
      minHeight={"100vh"}
      sx={{
        backgroundImage: `url('${bgUrl}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      position="relative"
    >

      <Grid
        container
        direction={"column"}
        justifyContent="center"
        alignItems={"center"}
        rowSpacing={2}
        pb={4}
        mb={4}
        sx={{
          // transform: "rotateY(12deg) rotateZ(-5deg) skewX(-5deg) ",
          // WebkitTransform: "rotateZ(-5deg) skewX(-5deg)" /* Safari & Chrome */,
          // MozTransform:
          //   "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Firefox */,
          // MsTransform:
          //   "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Internet Explorer */,
          // OTransform: "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Opera */,
        }}
      >
        <Grid item xs={12} align={"center"}>
          <Box
            sx={{
              borderBottom: "3px solid #eee",
              boxShadow: " 0 3px 2px -2px  #9ecaed;",
              width: "fit-content",
            }}
          >
            <Typography
              variant={"h1"}
              color={"white"}
              sx={{
                fontFamily: MAIN_FONT,
                fontSize: "50px",
                fontWeight: "900",
                lineHeight: "80px",
                letterSpacing: "0em",
              }}
            >
               {localLeaderboard.skill_name || "N/A"}
            </Typography>
          </Box>
        </Grid>

        {/* No any result */}
        {localLeaderboard?.result?.length === 0 &&
          !("break_time" in localLeaderboard) ? (
          <Grid item sx={{ width: "90%" }}>
            <NothingDisplay />
          </Grid>
        ) : null}
        {/* // Leader Display */}
        {/* <pre>
          Leaderboard: {JSON.stringify(localLeaderboard, null, 2)}
        </pre> */}
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
          mt={3}
        >
          {/* <pre>QueryCode: {leaderboard.query_code}</pre> */}
          <Grid item xs={5} sx={{ height: "100%" }} >
            <Stack direction="column" spacing={2} >
              {top3List}
            </Stack>
          </Grid>
          <Grid item xs={5} sx={{ height: "100%" }} >
            <Stack direction="column" spacing={2} >
              {restList}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
