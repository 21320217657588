import DashboardLayout from "../layouts/dashboard";
import TestApi from "../views/test/TestApi";
import TestEventList from "../views/test/TestEventList";
import TestSimple from "../views/test/TestSimple";
import TestSkillInputForm from "../views/test/TestSkillInputForm";
import TestSkillSelectionList from "../views/test/TestSkillSelectionList";
import TestSections from "../views/test/TestSections";
import TestDialog from "../views/test/TestDialog";
import TestEventSkillInfoCard from "../views/test/TestEventSkillInfoCard";
import TestCsvParser from "../views/test/TestCsvParser";
import TestQRCode from "../views/test/TestQRCode";
import TestInputForm from "../views/test/TestInputForm";
import TestImportHealth from "../views/test/TestImportHealth";
import TestToast from "../views/test/TestToast";
import TestSponsorLogo from "../views/test/TestSponsorLogo";
import TestMemberQueryQRCardList from "../views/test/TestMemberQueryQRCardList";
import TestMemberQueryCardSection from "../views/test/TestMemberQueryCardSection";
import TestMemberQueryQRCardPrintList from "../views/test/TestMemberQueryQRCardPrintList";
import TestSportDisplay from "../views/test/TestSportDisplay";
import TestCommonTable from "../views/test/TestCommonTable";
import TestCommonBreadcrumb from "../views/test/TestCommonBreadcrumb";
import TestMqttBroker from "../views/test/TestMqttBroker";
import TestEventLeaderboardQueryForm from "../views/test/TestEventLeaderboardQueryForm";
import TestCommonDataSelection from "../views/test/TestCommonDataSelection";
import TestTimeHelper from "../views/test/TestTimeHelper";
import TestCommonDatePicker from "../views/test/TestCommonDatePicker";
import TestEventStatisticQueryForm from "../views/test/TestEventStatisticQueryForm";
import TestCommonDashboardCard from "../views/test/TestCommonDashboardCard";
import TestEventStatCard from "../views/test/TestEventStatCard";
import TestAccordion from "../views/test/TestAccordion";
import TestMemberSkillDataTable from "../views/test/TestMemberSkillDataTable";
import TestCommonDateRangePicker from "../views/test/TestCommonDateRangePicker";
import TestOverallSportDisplay from "../views/test/TestOverallSportDisplay";
import TestGroupMemberSelection from "../views/test/TestGroupMemberSelection";
import TestMemberSkillInputForm from "../views/test/TestMemberSkillInputForm";

const route = {
  element: <DashboardLayout />,
  path: "/test",
  children: [
    { path: "memberSkillInputForm", element: <TestMemberSkillInputForm /> },
    { path: "groupMemberSelection", element: <TestGroupMemberSelection />},
    { path: "overallSportDisplay", element: <TestOverallSportDisplay /> },
    { path: "commonDateRangePicker", element: <TestCommonDateRangePicker /> },
    { path: "memberSkillDataTable", element: <TestMemberSkillDataTable />},
    { path: "timeHelper", element: <TestTimeHelper /> },
    { path: "accordion", element: <TestAccordion /> },
    { path: "eventStatCard", element: <TestEventStatCard /> },
    { path: "commonDatePicker", element: <TestCommonDatePicker /> },
    { path: "commonDashboardCard", element: <TestCommonDashboardCard /> },
    { path: "eventStatisticQueryForm", element: <TestEventStatisticQueryForm /> },
    // TestEventLeaderboardQueryForm
    { path: "commonDataSelection", element: <TestCommonDataSelection /> },
    { path: "eventLeaderboardQueryForm", element: <TestEventLeaderboardQueryForm /> },
    { path: "sportDisplay", element: <TestSportDisplay /> },
    { path: "memberQRCard", element: <TestMemberQueryQRCardList /> },
    { path: "memberQRSection", element: <TestMemberQueryCardSection /> },
    { path: "memberQRPrint", element: <TestMemberQueryQRCardPrintList /> },
    { path: "simple", element: <TestSimple /> },
    { path: "qrcode", element: <TestQRCode /> },
    { path: "toast", element: <TestToast /> },
    { path: "sponsorLogo", element: <TestSponsorLogo /> },
    { path: "inputForm", element: <TestInputForm /> },
    { path: 'csvParser', element: <TestCsvParser /> },
    { path: "section", element: <TestSections /> },
    { path: "healthImport", element: <TestImportHealth /> },
    { path: "api", element: <TestApi /> },
    { path: "eventList", element: <TestEventList /> },
    { path: "skillSelection", element: <TestSkillSelectionList /> },
    { path: "skillInput", element: <TestSkillInputForm /> },
    { path: "dialog", element: <TestDialog /> },
    { path: "skillInfoCard", element: <TestEventSkillInfoCard /> },
    { path: "commonTable", element: <TestCommonTable /> },
    { path: "commonBreadcrumb", element: <TestCommonBreadcrumb /> },
    { path: "mqttBroker", element: <TestMqttBroker /> },
  ],
};

export default route;
