import React from "react";
import { useState, useEffect } from "react";
import { useTranslation, Translation } from "react-i18next";
import { useFormik } from "formik";
// import { Formik, Field, Form, useField, useFormikContext } from 'formik';
import { InfoApi } from "../../api/InfoApi";
import {
  Stack,
  TextField,
  InputAdornment,
  Box,
  Button,
  CardContent,
  Card,
  Typography,
  OutlinedInput,
  Alert,
  AlertTitle,
} from "@mui/material";

import { localizedName } from "../../helper/LangHelper";
// import Typography from "../../theme/overrides/Typography";
import ConfirmInputDialog from "./ConfirmInputDialog";
import { AdminApi } from "../../api/AdminApi";

// import StandardInputForm from "./skill/StandardInputForm";
import MultiInputForm from "./skill/MultiInputForm";
import { toast } from "react-toastify";

import SkillMqttAutoInputForm from "./SkillMqttAutoInput";
import SkillCsvImport from "./SkillCsvImport";

import { MqttContextProvider } from "../mqtt/MqttProvider";
import CsvImportHelper from "../../helper/CsvImportHelper";

import GroupMemberSelection from "../../components/GroupMemberSelection/GroupMemberSelection";
import MockData from "../../helper/MockData";

function StandartTestForm({ unitName, onChange }) {
  const [skillValue, setSkillValue] = useState(0);

  useEffect(() => {
    onChange({
      skillValue,
    });
  }, [skillValue]);

  const handleOnChange = (e, type) => {
    // console.debug("handleOnChange called ", formik.values);
    if (type === "skillValue") {
      setSkillValue(e.target.value);
    }
  };

  return (
    <OutlinedInput
      id="skillValue"
      value={skillValue}
      // value={formik.values.skillValue}
      // onChange={formik.handleChange}

      onChange={(e) => {
        // formik.handleChange(e);
        handleOnChange(e, "skillValue");
        // handleOnChange();
      }}
      endAdornment={<InputAdornment position="end">{unitName}</InputAdornment>}
      aria-describedby="outlined-weight-helper-text"
    />
  );
}

export default function MemberSkillInputForm({ skill, eventID, orgID }) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmResult, setConfirmResult] = useState(null);
  const [skillValue, setSkillValue] = useState(0);
  const [member, setMember] = React.useState(null); // [member, setMember
  const [memberList, setMemberList] = React.useState(null); // [member, setMember
  // const [memberQRCode, setMemberQRCode] = React.useState(null); // [member, setMember
  const unitName = t("unit." + skill.unit);

  const skillName = localizedName(skill, "name");

  const loadMemberList = async (orgID) => {
      let result = await InfoApi.getMemberListByOrg(orgID);
      if(result === null) {
        toast.error("讀取參加者資料錯誤");
        return;
      }
      setMemberList(result);
  }

  useEffect(() => {
    // console.debug("memberList: ", memberList);
    // if (memberList === null) {
      loadMemberList(orgID);
    // setMemberList(MockData.getMemberList());
    // }
  }, []);


  const formik = useFormik({
    initialValues: {
      qrCode: "",
      // skillValue: 0,
      // skillData: {},  // { left: 3, right: 3}
    },
  });

  useEffect(() => {
    console.debug("useEffect: member: ", member);
    // setMemberQRCode(member ? member.qrcode_id : "");
    // setFieldValue("qrCode", member ? member.qrCode : "");
    // if (member !== null) 
    // setMemberQRCode(member ? member.qrCode : "");
    // formik.setFieldValue("qrCode", member.qrCode);
  }, [member]);

  // useEffect(() => {
  //   // console.debug("member: ", member);
  //   if (member !== null) {
  //     // console.debug("member: ", member);
  //     // console.debug("member: ", member);
  //     formik.setFieldValue("qrCode", member.qrCode);
  //   }
  // }, [member]);  


  const onSubmit = () => {
    console.debug("onSubmit: ", formik.values);
    if (member === null) {
      alert("請選擇參加者");
      return;
    }

    callCheckInput();
  };

  const callCheckInput = async () => {
    const qrCode = member.qrcode_id;
    const skillCode = skill.code;
    const score = skillValue;

    // ken: todo: remove useless eventID
    const result = await AdminApi.checkSkillInput(
      eventID,
      qrCode,
      skillCode,
      score
    );
    console.debug("result=", result);
    setConfirmResult(result);

    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onInputChange = (data) => {
    console.debug("onInputChange: ", data);
    setSkillValue(data.skillValue);
  };

  let inputForm = undefined;

  // ----- Different Form for Different Sport ------

  if (skill.code === "situp") {
    inputForm = (
      <MultiInputForm
        formula="max"
        inputCount={1}
        unitName={unitName}
        onChange={onInputChange}
      />
    );
  } else if (skill.code === "vjump") {
    inputForm = (
      <MultiInputForm
        formula="max"
        inputCount={2}
        unitName={unitName}
        onChange={onInputChange}
      />
    );
  } else if (skill.code === "handgrip") {
    inputForm = (
      <MultiInputForm
        formula="avg"
        inputCount={2}
        labelList={["左手", "右手"]}
        unitName={unitName}
        onChange={onInputChange}
      />
    );
  } else if (skill.code === "balance") {
    inputForm = (
      <MultiInputForm
        formula="avg"
        inputCount={2}
        labelList={["左", "右"]}
        unitName={unitName}
        onChange={onInputChange}
      />
    );
  } else if (skill.code === "punch") {
    inputForm = (
      <MultiInputForm
        formula="max"
        inputCount={2}
        labelList={[]}
        unitName={unitName}
        onChange={onInputChange}
      />
    );
  } else {
    inputForm = (
      <StandartTestForm unitName={unitName} onChange={onInputChange} />
    );
  }

  const infoMember = (member) => {
    if(member === null) {
      return "請選擇參加者";
    }

    return member.display_name + " --  "
      + member.cls + "班 " + member.cls_num + "號";
  };

  // let qrCodeStr = JSON.stringify(memberQRCode);

  return (
    <>
      {confirmResult && (
        <ConfirmInputDialog
          eventID={eventID}
          confirmResult={confirmResult}
          open={dialogOpen}
          onClose={handleDialogClose}
        />
      )}
      <Box>
        <Card sx={{ mt: 3 }}>
          <CardContent>
            {
              memberList && (
                <GroupMemberSelection memberList={memberList} onSelectMember={
                  (member) => {
                    console.log("member: ", member);
                    setMember(member);
                    // formik.setFieldValue("qrCode", member.qrCode);
                  }
                } />)
            }
          </CardContent>
        </Card>
        <Card sx={{ mt: 3 }}>
          <CardContent>
            {infoMember(member)}
          </CardContent>

        </Card>
        
        <Box sx={{ my: 2 }} />
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Stack spacing={3}>
              <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
                測試項目：{skillName}
              </Typography>
              {/* <OutlinedInput
                            value={formik.values.skillValue}
                            id="skillValue"
                            onChange={formik.handleChange}
                            endAdornment={<InputAdornment position="end">{unitName}</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                        /> */}

              {inputForm}

              <Button
                variant="contained"
                onClick={onSubmit}
                color="primary"
                sx={{ width: "100%" }}
              >
                提交數據
              </Button>
              {/* <pre>skillValue: {skillValue}</pre> */}
            </Stack>
          </CardContent>
        </Card>
        {/* Auto Input */}
        {skill?.import_setting?.auto_import &&
          "devices" in skill?.import_setting?.auto_import ? (
          <MqttContextProvider>
            <SkillMqttAutoInputForm
              skill={skill}
              eventID={eventID}
              qrCode={formik.values.qrCode}
            />
          </MqttContextProvider>
        ) : null}
        {/* Csv Import */}
        {skill?.import_setting?.file_import &&
          "parser" in skill?.import_setting?.file_import &&
          CsvImportHelper.getExistingParserName().includes(
            skill?.import_setting?.file_import?.parser
          ) ? (
          <SkillCsvImport
            skill={skill}
            eventID={eventID}
            qrCode={formik.values.qrCode}
          />
        ) : null}

        {!CsvImportHelper.getExistingParserName().includes(
          skill?.import_setting?.file_import?.parser
        ) &&
          skill?.import_setting?.file_import !== undefined &&
          "parser" in skill?.import_setting?.file_import ? (
          <Alert severity="warning" sx={{ mt: 3 }}>
            <AlertTitle>Parser NOTFOUND</AlertTitle>
            Parser —{" "}
            <strong>{skill?.import_setting?.file_import.parser}</strong> not
            exist, please check your skill setting.
          </Alert>
        ) : null}
      </Box>
    </>
  );
}
