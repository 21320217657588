import React, { } from "react";
import { Grid, Box, Button } from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import Typography from "../../theme/overrides/Typography";


export default function SkillSelectionList({
    skillList, onSelect
}) {

    const handleClick = (skill) => {
        console.log("handleClick: ", skill);
        onSelect(skill);
    }


    return (
        <Grid container spacing={2}>


            {
                skillList.map((skill, index) => {
                    let skillName = localizedName(skill, 'name');
                    let fitnessName = localizedName(skill, 'fitness_type');
                    return (
                        <Grid item xs={6} md={3}>
                            <Button fullWidth key={index} variant="contained" color="primary"
                                onClick={
                                    () => handleClick(skill)
                                }>
                                {fitnessName}
                                <br />
                                ({skillName})
                            </Button>
                        </Grid>
                    );
                })
            }
        </Grid>

    );
}