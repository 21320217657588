import { Backdrop } from "@mui/material";
import {
  getDataRequest,
  formatQueryResponse,
} from "../../../helper/AxiosHelper";

function fetchDataQuery(
  fetchDataConfig = {},
  query = {},
  urlParma = {},
  queryResultSetter = () => {},
  backdropSetter = () => {}
) {
  let urlWithQuery = `${fetchDataConfig.url}?query=${JSON.stringify(query)}`;
  if ("fixedFilter" in fetchDataConfig && query?.filters?.length === 0) {
    return;
  }
  if ("extraQuery" in fetchDataConfig) {
    fetchDataConfig.extraQuery.forEach((extraQueryParma) => {
      if (urlParma[extraQueryParma.field] === undefined) return;
      urlWithQuery += `&${extraQueryParma.key}=${
        urlParma[extraQueryParma.field]
      }`;
    });
  }
  getDataRequest(urlWithQuery, (isSuccess, _data) => {
    if (!isSuccess) {
      return false;
    }
    queryResultSetter(() => formatQueryResponse(_data));
    backdropSetter(() => false);
  });
}

const MemberApi = {
  fetchDataQuery,
};

export default MemberApi;
