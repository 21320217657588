import {
    getLocalStorageUser,
} from "./LocalStorageHelper";


export const getStoredAccount = () => {
    var user = getLocalStorageUser();
    if (!user) {
        return null;
    }

    try {
        return JSON.parse(user);
    } catch (error) {
        console.log(error);
        return null;
    }
}