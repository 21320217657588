import { TextField, Grid } from "@mui/material";

import { useFormik } from "formik";

// export function setFormik(_formik) {
//   FORMIK = _formik;
// }

const getID = (index) => {
  return `Form-Component-${new Date().getTime()}-${index}`;
};
// -------

export function isError(formik, key) {
  return formik.errors[key] && formik.touched[key];
}
export function getErrorMsg(formik, key) {
  return isError(formik, key) ? formik.errors[key] : null;
}
export async function validateForm(formik) {
  await formik.validateForm();

  return !formik.isValid;
}

const InputBox = ({ formik, formConfig, index }) => {
  let textFieldProps = formConfig.props;

  return (
    <TextField
      {...textFieldProps}
      key={getID(index)}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={isError(formik, textFieldProps.id)}
      helperText={getErrorMsg(formik, textFieldProps.id)}
      fullWidth
    />
  );
};

const FormControlMap = {
  input: InputBox,
  // select: getSelection,
};

const FormBuilder = ({ formikInstance, formControl, inGrid = false }) => {
  const formik = useFormik(formikInstance);
  //   console.log(formik);
  return formControl.map((item, index) => {
    let variant = item.variant || "input";

    let FormComponent = FormControlMap[variant];

    if (FormComponent === undefined) return <></>;

    return (
      <GridSection item={item} index={index} key={`Form-Section-${index}`}>
        <FormComponent
          formik={formik}
          formConfig={item}
          index={index}
          key={`Form-Node-${index}`}
        />
      </GridSection>
    );
  });
};

const GridSection = ({ children, item, index }) => {
  let gridStyle = "size" in item ? item.size : { xs: 12 };
  return (
    <Grid item {...gridStyle}>
      {children}
    </Grid>
  );
};

export default FormBuilder;
