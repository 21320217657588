import { Box, Typography, Grid, Divider, Stack } from "@mui/material";

const MAIN_FONT = "Noto Sans TC";

function memberName(memberData) {
  const { name, member } = memberData;

  let info = name;
  const { group } = member;

  if (group !== undefined && group !== '') {
    return `${group} ${name}`
  } else {
    return name
  }

  // return name;
}


// itemSize = 'big' / 'normal'
export default function MosaicSportDisplayItem({ content, itemSize = 'big' }) {

  console.debug("content: ", content);

  const StringMap = {
    // 1: "金獎",
    // 2: "銀獎",
    // 3: "銅獎",
  };
  const MedalImgMap = {
    1: "/medal/gold.png",
    2: "/medal/silver.png",
    3: "/medal/bronze.png",
  };

  const boxWidth = itemSize === 'big' ? 600 : 420;
  const boxHeight = itemSize === 'big' ? 120 : 80;

  const MainGrid = () => {
    let { rank, score, unit, members } = content;

    // console.debug("members: ", members);
    let memberListStr = members == null || members == undefined ? "" : members.map((m) => m.name).join(", ");

    let medalIcon = MedalImgMap[rank] ? (
      <Box
        component="img"
        src={MedalImgMap[rank]}
        maxWidth={40}
        maxHeight={40}
      />
    ) : <Box src={{width:10}}></Box>;

    const rankVar = itemSize === 'big' ? "h3" : "h5";
    const scoreVar = itemSize === 'big' ? "h4" : "h6";
    const memberVar = itemSize === 'big' ? "h4" : "h6";
   
    const rankGrid = (<Box whiteSpace="nowrap">
      <Typography variant={rankVar}>{"第" + rank + "名"}</Typography>
    </Box>);

    const scoreGrid = (
      <Box whiteSpace="nowrap">
        <Typography variant={scoreVar}>{score + unit}</Typography>
      </Box>
    )

    const memberGrid = (
    <Box whiteSpace="warp">
      <Typography variant={memberVar}>{memberListStr}</Typography>
    </Box>
    );

    return (
      <Stack
        direction={"row"}
        justifyContent="start"
        alignItems={"center"}
        gap={3}
        color={"white"}
        height={"100%"}
        width={"100%"}
        
      >
        {medalIcon}

        {rankGrid}
        {scoreGrid}
        {memberGrid}
      </Stack >
    );
  };


  return (
    <Box
      sx={{
        width: boxWidth + "px",
        height: boxHeight + "px",
        border: "0px solid #eee",
        borderRadius: "100px",
        background: "rgba(0, 0, 0, 0.3)",
      }}
    >

      <Grid
        container
        justifyContent={"start"}
        alignItems={"middle"}
        height={"100%"}
        py={1}
        px={2}
      >
        <MainGrid content={content} />
      </Grid>

    </Box>
  );
}
