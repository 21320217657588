import {
    Typography,
    Box,
} from "@mui/material";
import { useState, useEffect } from 'react';
import React from 'react';
import MockData from "../../helper/MockData";
import { useNavigate } from "react-router-dom";
import { InfoApi } from "../../api/InfoApi";
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";


import SkillSelectionList from "../../components/app/SkillSelectionList";


export default function SkillSelectionSection({ event }) {
    const [skillList, setSkillList] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    let lang = getLang();

    useEffect(() => {
        loadData();
    }, []);

    const eventSkillList = event ? event.skill_setting.combined : null;

    const filterSkillList = (_skillList) => {
        return _skillList.filter((skill) => {
            return eventSkillList.includes(skill.code);
        });
    }

    const loadData = async () => {
        // console.debug("currentMemberID: ", currentMemberID);
        const result = await InfoApi.getSkillList();
        // console.debug("result: ", result);
        setSkillList(filterSkillList(result));
    };

    const onSelectSkill = (skill) => {
        console.debug("skill=", skill);

        const options = {
            state: { skill, event }
        };
        navigate(`/skill-input/${skill.code}`, options);
    }


    return (
        <Box>{
            (
                skillList ?
                    <SkillSelectionList skillList={skillList} onSelect={onSelectSkill} />
                    :
                    <Box>Loading...</Box>
            )
        }
            <pre>
                {/* {JSON.stringify(skillList, null, 2)} */}
                {/* {JSON.stringify(eventSkillList, null, 2)} */}
                {
                    // JSON.stringify(filterSkillList(skillList), null, 2)
                }
            </pre>
        </Box>
    )
}
