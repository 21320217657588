// component
import SvgColor from "../../../components/svg-color";

import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import QrCodeIcon from "@mui/icons-material/QrCode";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SearchIcon from '@mui/icons-material/Search';
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);


export const userNavConfig = [
  {
    title: "主頁", //'home',
    path: "/user/dashboard",
    icon: <HomeIcon />,
  },
  {
    title: "排行榜", // 'LEADERBOARD',
    path: "/user/leaderboard",
    icon: <MilitaryTechIcon />,
  },
  {
    title: "活動統計", // 'STATISTIC',
    path: "/user/statistic",
    icon: <EqualizerIcon />,
  },
  {
    title: "數據查詢", // 'LEADERBOARD',
    path: "/user/dataQuery",
    icon: <SearchIcon />,
  },
  {
    title: "QRCode", // 'LEADERBOARD',
    path: "/user/qrcode",
    icon: <QrCodeIcon />,
  },
  //leaderboard
];

export const adminNavConfig = [
  {
    title: "主頁", //'home',
    path: "/home",
    icon: <HomeIcon />,
  },
  {
    title: "機構管理", //'org',
    path: "/organizations",
    icon: <ApartmentIcon />,
  },

  {
    title: "排行榜", // 'LEADERBOARD',
    path: "/adminLeaderboard",
    icon: <MilitaryTechIcon />,
  },

  {
    title: "活動統計", // 'LEADERBOARD',
    path: "/adminStatistic",
    icon: <EqualizerIcon />,
  },

  {
    title: "數據查詢", // 'LEADERBOARD',
    path: "/adminDataQuery",
    icon: <SearchIcon />,
  },
  {
    title: "MEMBER QRCODE",
    path: "/member-qr",
    icon: <QrCodeIcon />,
  },

];

