import { Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import MockData from "../../helper/MockData";
import { InfoApi } from "../../api/InfoApi";
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";

import EventList from "../../components/app/EventList";

export default function EventListSection() {
  const [eventList, setEventList] = useState(null);
  const { t } = useTranslation();

  let lang = getLang();

  useEffect(() => {
    loadEventList();
  }, []);

  const loadEventList = async () => {
    // console.debug("currentMemberID: ", currentMemberID);
    const result = await InfoApi.getEventList();
    console.debug("result: ", result);
    setEventList(result);
  };

  return (
    <Box>
      {eventList ? <EventList eventList={eventList} /> : <Box>Loading...</Box>}
    </Box>
  );
}
