import React from "react";
import { useState, useEffect } from "react";
import { useTranslation, Translation } from "react-i18next";
import { useFormik } from "formik";
import {
  Stack,
  TextField,
  InputAdornment,
  Box,
  Button,
  CardContent,
  Card,
  Typography,
  OutlinedInput,
  Alert,
  AlertTitle,
} from "@mui/material";
import QrReader from "modern-react-qr-reader";

import { localizedName } from "../../helper/LangHelper";
// import Typography from "../../theme/overrides/Typography";
import ConfirmInputDialog from "../app/ConfirmInputDialog";
import { AdminApi } from "../../api/AdminApi";

// import StandardInputForm from "./skill/StandardInputForm";
import MultiInputForm from "./skill/MultiInputForm";
import { toast } from "react-toastify";

import SkillMqttAutoInputForm from "./SkillMqttAutoInput";
import SkillCsvImport from "./SkillCsvImport";

import { MqttContextProvider } from "../../components/mqtt/MqttProvider";
import CsvImportHelper from "../../helper/CsvImportHelper";

function StandartTestForm({ unitName, onChange }) {
  const [skillValue, setSkillValue] = useState(0);

  useEffect(() => {
    onChange({
      skillValue,
    });
  }, [skillValue]);

  const handleOnChange = (e, type) => {
    // console.debug("handleOnChange called ", formik.values);
    if (type === "skillValue") {
      setSkillValue(e.target.value);
    }
  };

  return (
    <OutlinedInput
      id="skillValue"
      value={skillValue}
      // value={formik.values.skillValue}
      // onChange={formik.handleChange}

      onChange={(e) => {
        // formik.handleChange(e);
        handleOnChange(e, "skillValue");
        // handleOnChange();
      }}
      endAdornment={<InputAdornment position="end">{unitName}</InputAdornment>}
      aria-describedby="outlined-weight-helper-text"
    />
  );
}

export default function SkillInputForm({ skill, eventID }) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmResult, setConfirmResult] = useState(null);
  const [skillValue, setSkillValue] = useState(0);

  const unitName = t("unit." + skill.unit);

  const skillName = localizedName(skill, "name");

  const formik = useFormik({
    initialValues: {
      qrCode: "",
      // skillValue: 0,
      // skillData: {},  // { left: 3, right: 3}
    },
  });

  const onSubmit = () => {
    console.debug("onSubmit: ", formik.values);
    if (formik.values.qrCode === "") {
      alert("請輸入參加者QR碼");
      return;
    }

    if (formik.values.qrCode.length < 4) {
      alert("參加者QR號碼不正確");
      return;
    }

    callCheckInput();
  };

  const callCheckInput = async () => {
    const qrCode = formik.values.qrCode;
    const skillCode = skill.code;
    const score = skillValue;

    // ken: todo: remove useless eventID
    const result = await AdminApi.checkSkillInput(
      eventID,
      qrCode,
      skillCode,
      score
    );
    console.debug("result=", result);
    setConfirmResult(result);

    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleQRError = (err) => {
    // setError(err);
  };

  const handleQRScan = (data) => {
    if (!data) return;
    if (data === formik.values.qrCode) return;

    console.debug("QR data=", data);
    if (data !== "" && data !== undefined) {
      formik.setFieldValue("qrCode", data);
      toast.success("QR code scanned");

      // setData(data);
      // this.setState({ showCam: false });
    }
  };

  const onInputChange = (data) => {
    console.debug("onInputChange: ", data);
    setSkillValue(data.skillValue);
  };

  let inputForm = undefined;

  // ----- Different Form for Different Sport ------

  if (skill.code === "situp") {
    inputForm = (
      <MultiInputForm
        formula="max"
        inputCount={1}
        unitName={unitName}
        onChange={onInputChange}
      />
    );
  } else if (skill.code === "vjump") {
    inputForm = (
      <MultiInputForm
        formula="max"
        inputCount={2}
        unitName={unitName}
        onChange={onInputChange}
      />
    );
  } else if (skill.code === "handgrip") {
    inputForm = (
      <MultiInputForm
        formula="avg"
        inputCount={2}
        labelList={["左手", "右手"]}
        unitName={unitName}
        onChange={onInputChange}
      />
    );
  } else if (skill.code === "balance") {
    inputForm = (
      <MultiInputForm
        formula="avg"
        inputCount={2}
        labelList={["左", "右"]}
        unitName={unitName}
        onChange={onInputChange}
      />
    );
  } else if (skill.code === "punch") {
    inputForm = (
      <MultiInputForm
        formula="max"
        inputCount={2}
        labelList={[]}
        unitName={unitName}
        onChange={onInputChange}
      />
    );
  } else {
    inputForm = (
      <StandartTestForm unitName={unitName} onChange={onInputChange} />
    );
  }

  return (
    <>
      {confirmResult && (
        <ConfirmInputDialog
          eventID={eventID}
          confirmResult={confirmResult}
          open={dialogOpen}
          onClose={handleDialogClose}
        />
      )}
      <Box>
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Stack spacing={3}>
              <TextField
                name="qrCode"
                id="qrCode"
                label="用戶QR碼"
                value={formik.values.qrCode}
                onChange={formik.handleChange}
              />

              <Box
                component={QrReader}
                // onResult={(result, error) => {
                //     // console.debug("result=", result);
                //     // console.debug("error=", error);
                //     if (!!result) {
                //         setData(result?.text);
                //     }
                //     setError(error ?? "No result yet");
                // }}
                delay={300}
                onError={handleQRError}
                onScan={handleQRScan}
                constraints={{
                  facingMode: "environment",
                }}
                sx={{ width: 300, height: "auto" }}
              />

              {/* <Button variant="contained" color="primary" sx={{ width: '100%' }}>
                            使用 QR Code 獲取
                        </Button> */}
            </Stack>
          </CardContent>
        </Card>
        <Box sx={{ my: 2 }} />
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Stack spacing={3}>
              <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
                測試項目：{skillName}
              </Typography>
              {/* <OutlinedInput
                            value={formik.values.skillValue}
                            id="skillValue"
                            onChange={formik.handleChange}
                            endAdornment={<InputAdornment position="end">{unitName}</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                        /> */}

              {inputForm}

              <Button
                variant="contained"
                onClick={onSubmit}
                color="primary"
                sx={{ width: "100%" }}
              >
                提交數據
              </Button>
              {/* <pre>skillValue: {skillValue}</pre> */}
            </Stack>
          </CardContent>
        </Card>
        {/* Auto Input */}
        {skill?.import_setting?.auto_import &&
        "devices" in skill?.import_setting?.auto_import ? (
          <MqttContextProvider>
            <SkillMqttAutoInputForm
              skill={skill}
              eventID={eventID}
              qrCode={formik.values.qrCode}
            />
          </MqttContextProvider>
        ) : null}
        {/* Csv Import */}
        {skill?.import_setting?.file_import &&
        "parser" in skill?.import_setting?.file_import &&
        CsvImportHelper.getExistingParserName().includes(
          skill?.import_setting?.file_import?.parser
        ) ? (
          <SkillCsvImport
            skill={skill}
            eventID={eventID}
            qrCode={formik.values.qrCode}
          />
        ) : null}

        {!CsvImportHelper.getExistingParserName().includes(
          skill?.import_setting?.file_import?.parser
        ) &&
        skill?.import_setting?.file_import !== undefined &&
        "parser" in skill?.import_setting?.file_import ? (
          <Alert severity="warning" sx={{ mt: 3 }}>
            <AlertTitle>Parser NOTFOUND</AlertTitle>
            Parser —{" "}
            <strong>{skill?.import_setting?.file_import.parser}</strong> not
            exist, please check your skill setting.
          </Alert>
        ) : null}
      </Box>
    </>
  );
}
