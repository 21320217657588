import React from "react";
import { Card, Box, Stack, Grid, Button, Chip, Typography } from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import { useNavigate } from "react-router-dom";
import { inf } from "numeric";
// import { MemberApi } from "../api/MemberApi";
// import { useSelector, useDispatch } from "react-redux";
// import { memberID, updateAuthState } from "../reducers/userSlice";

function memberCardInfo(memberData) {
  // let info = "";
  let infoArray = [];
  if (memberData.phone_num !== '') {
    infoArray.push(memberData.phone_num);
  }
  console.debug("memberData: ", memberData);

  if (memberData.member !== null) {
    let classInfo = "";
    if (memberData.group !== undefined && memberData.group !== '') {
      classInfo = memberData.group;
    }
    if (memberData.cls !== undefined && memberData.cls !== '') {
      classInfo += ` (${memberData.cls} ${memberData.cls_num})`;
    }

    if(classInfo !== "") {
      infoArray.push(classInfo);
    }    
  }

  const info = infoArray.join(" / ");
  return `${memberData.name} - ${info}`;
}

const rankCardStyle = {
  p: 1,
  backgroundColor: "primary.lighter",
  borderRadius: 1,
  border: 1,
  borderColor: "primary.light",
  height: "100%",
}

export default function AdminLeaderboardCard({ leaderboardItem }) {
  const leaderResult = leaderboardItem.result || [];
  const rankList = leaderResult.map((item, index) => (
    <Grid item xs={12} sm={6} md={4} lg={3} sx={{p: 1}}>
      <Stack sx={rankCardStyle} direction="column">
        <Typography variant="h5">
          Rank {item.rank} - {item.score}
          {item.unit}{" "}
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={2}>
          {item.members.map((member, index) => (
            <Chip sx={{ fontSize: "1.0rem" }} label={memberCardInfo(member)} />
            // <Box>
            //   <Typography variant="body1">            
            // {memberCardInfo(member)}
            //   </Typography>
            // </Box>
          ))}
        </Box>
      </Stack>
    </Grid>
  ));

  return (
    <Card variant="outlined" sx={{ p: 2, mb: 3 }}>
      <Stack direction="column">
        <Typography variant="h4">{leaderboardItem?.skill_name}</Typography>
        <Stack direction="column" gap={2}>
          <Grid container>
            {rankList}
          </Grid>
        </Stack>
      </Stack>
      {/* <Box>
        <pre>{JSON.stringify(leaderboardItem, null, 2)}</pre>
      </Box> */}
    </Card>
  );
}
