import axios from "../helper/AxiosHelper";
import ApiHelper from "./ApiHelper";


export const getMemberListByOrg = async (orgID, fields = "default") => 
{
  const page = 1;
  const pageSize = -1; 
  const filters = [
    {
      field: "organization_id",
      op: "eq",
      value: orgID,
    },
  ];

  let result = await queryMembers(page, pageSize, filters, fields);
  if(result === null || result === false) {
    return null;
  }

  return result.data;
}

export const queryMembers = async (page, pageSize, filters, fields = 'default') => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.asc",
  };


  const queryJsonStr = JSON.stringify(query);

  const url = `/member/query?query=${queryJsonStr}&fields=${fields}`;

  const response = await axios.get(url, {});

  return ApiHelper.processResponse(response);
}

// admin/getEventList/5

const getUserEventList = async (userID, fields = "adminInfo") => {
  // admin/getEventList/5
  const url = `/admin/getEventList/${userID}?fields=${fields}`;

  const response = await axios.get(url, {});

  return ApiHelper.processResponse(response);
}

const getEventList = async (fields = "adminInfo") => {

  const url = `/event?fields=${fields}`;

  const response = await axios.get(url, {});

  return ApiHelper.processResponse(response);
};


const getSkillList = async () => {

  const url = `/skillInfo`;

  const response = await axios.get(url, {});

  return ApiHelper.processResponse(response);
};

const getSkillDetail = async (skillID) => {

  const url = `/skillInfo/show/${skillID}`;

  const response = await axios.get(url, {});

  return ApiHelper.processResponse(response);
};


const exportMemberSkillData = async (filters, option={}) => {
  const query = {
    filters: filters || [],
    page : option.page || 1,
    page_size : option.pageSize || -1,
    sort: "id.desc",
  };
  const type = option.type || 'default';
  const format = option.format || 'csv';


  const queryJsonStr = JSON.stringify(query);

  const url = `/memberSkillData/export?query=${queryJsonStr}&type=${type}&format=${format}`;

  const response = await axios.get(url, {});

  return ApiHelper.processResponse(response);
};

export const queryMemberSkillData = async (page, pageSize, filters, fields = 'default') => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 100,
    sort: "id.desc",
  };


  const queryJsonStr = JSON.stringify(query);

  const url = `/memberSkillData/query?query=${queryJsonStr}&fields=${fields}`;

  const response = await axios.get(url, {});

  return ApiHelper.processResponse(response);
}


export const InfoApi = {
  // login,
  // logout,
  // getMemberListByOrg = async (orgID, fields = "default") => 
  getMemberListByOrg,
  exportMemberSkillData,
  queryMemberSkillData,
  queryMembers,
  getUserEventList,
  getEventList,
  getSkillList,
  getSkillDetail,
};
