import { useTheme } from '@mui/material/styles';
import { Grid, Box, Link, Divider, Container, Typography, Breadcrumbs } from '@mui/material';
import EventListSection from '../sections/_dashboard/EventListSection';
import SkillSelectionSection from '../sections/_dashboard/SkillSelectionSection';
import { useParams, useLocation } from "react-router-dom";
import EventCard from '../components/app/EventCard';

// ----------------------------------------------------------------------

export default function SkillListPage() {
  const { event_id } = useParams();

  

  let event = null;
  const { state } = useLocation();
  if (state !== undefined) {
    event = state.event;
  }

  return (
    <>
      <title> Dashboard | Minimal UI </title>


      <Container maxWidth="xl">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Typography color="text.primary">Skill Input</Typography>
        </Breadcrumbs>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {
              event && (
                <EventCard event={event} hideActions={true} />
              )
            }

          </Grid>
          <Grid item xs={12}>
            <SkillSelectionSection event={event} />
          </Grid>
        </Grid>
        <Box>
          {/* <pre>{event_id}</pre>
          <pre>
            {JSON.stringify(event, null, 2)}
          </pre> */}
        </Box>
      </Container>
    </>
  );
}
