import {
  Typography,
  Card,
  Box,
  Button,
  FormControl,
  Stack,
  MenuItem,
  InputLabel,
  Select,
  CardContent,
  CardHeader,
} from "@mui/material";

import MemberQueryQRCardPrintList from "../../components/app/MemberQueryQRCardPrintList";
import MockData from "../../helper/MockData";
import { useState, useEffect, useRef } from "react";
import { InfoApi } from "../../api/InfoApi";
import { toast } from "react-toastify";
import { RttRounded } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import { AdminApi } from "../../api/AdminApi";

export default function MemberQueryCardSection({ selectedOrgId }) {
  const [queryResult, setQueryResult] = useState(null);
  const [userOrgId, setUserOrgId] = useState(0);
  const [organizationList, setOrganizationList] = useState(false);

  const componentToPrint = useRef(null);

  selectedOrgId = selectedOrgId || 0;

  useEffect(() => {
    // toast.info("useEffect is called");
    // setQueryResult(MockData.getMemberQueryResult());
    AdminApi.getOrgnizationListCallback((isSuccess, msg, data) => {
      // if (isSuccess) {
      //     setOrganizationList(data);
      // } else {
      // console.debug("result: isSuccess=", isSuccess,
      //     " msg=", msg, " data=", data);

      if (isSuccess === false) {
        toast.error(msg);
        setOrganizationList(false);
        return;
      }

      // Filter out the organization that is not a school
      if (selectedOrgId !== 0) {
        data = data.filter((item) => parseInt(item.id) === selectedOrgId);
      }

      console.debug("data: ", data);


      setOrganizationList(data);
      if (data.length > 0) {
        setUserOrgId(data[0].id);
      }
      //}
    });
  }, []);

  const hasOrganizationList = organizationList ? true : false;

  const userTypeList = [
    { value: "school", label: "學校" },
    { value: "public", label: "公眾" },
  ];
  const userTypeSelectionItems = organizationList ? (
    organizationList.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name_zh} / {item.name_en}{" "}
        </MenuItem>
      );
    })
  ) : (
    <> </>
  );
  //let queryResult = MockData.getMemberQueryResult();

  const onUserTypeChanged = (event) => {
    setUserOrgId(event.target.value);
  };

  // const handlePrint = async () => {
  // };
  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  const handleQuery = async () => {
    const filters = [{ field: "organization_id", value: userOrgId, op: "eq" }];

    const result = await InfoApi.queryMembers(1, -1, filters);
    console.debug("result: ", result);
    if (result === false) {
      //alert("Success");
      toast.error("Failed to query members");
      return;
    }
    setQueryResult(result);
    // setOrganizationList(result);
    // toast
  };

  return (
    <Stack>
      <Box sx={{ px: 2 }}>
        <Card>
          <CardContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              {/* <pre>
                            {organizationList ? JSON.stringify(organizationList, null, 2) : "not ready"}
                        </pre> */}
              {hasOrganizationList && (
                <>
                  <FormControl fullWidth>
                    <InputLabel id="eventID-label">機構列表</InputLabel>
                    <Select
                      labelId="userType-label"
                      id="userType"
                      value={userOrgId}
                      label="用戶類型"
                      onChange={onUserTypeChanged}
                    >
                      {userTypeSelectionItems}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleQuery}
                  >
                    查詢
                  </Button>
                  <Button
                    variant="contained"
                    disabled={queryResult == null}
                    color="info"
                    onClick={handlePrint}
                  >
                    打印
                  </Button>
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <MemberQueryQRCardPrintList
        memberQueryResult={queryResult}
        componentToPrint={componentToPrint}
        pageSize={1000}
      />
    </Stack>
  );
}
