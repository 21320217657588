
export const timeToDateStr = (timeStr) => {
    const idx = timeStr.indexOf(" ");
    if (idx === -1) {
        return timeStr;
    }
    return timeStr.substring(0, idx);
};

export const getCurrentDateStr = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    if(day < 10){
        return `${year}-${month}-0${day}`;
    }
    return `${year}-${month}-${day}`;
}

export const timeToTimeStr = (timeStr) => {
    const idx = timeStr.indexOf(" ");
    if (idx === -1) {
        return timeStr;
    }
    return timeStr.substring(idx);
};

