import React, { useCallback, useEffect, useState } from "react";
import {
  ToggleButton, ToggleButtonGroup, Stack, Card,
  Box, Button, Grid, FormControl, RadioGroup,
  Paper, TextField, Typography, FormControlLabel, Radio
} from "@mui/material";

function getGroupList(memberList) {
  const groupList = [];
  memberList.forEach((member) => {
    const { group } = member;
    if (!groupList.includes(group)) {
      groupList.push(group);
    }
  });

  // Sort GroupList 
  groupList.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    return 1;
  });

  return groupList;
}

function GroupSelection({ groupList, onGroupChange }) {
  const [group, setGroup] = useState(null);

  const handleChange = (event, newGroup) => {
    console.debug("newGroup", newGroup);
    setGroup(newGroup);
    onGroupChange(newGroup);
  };

  return (
    <ToggleButtonGroup
      color="secondary"
      value={group}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      {
        groupList.map((group) => {
          return <ToggleButton key={group} value={group} aria-label={group}>
            {group}
          </ToggleButton>
        })
      }
    </ToggleButtonGroup>
  );

  // return (<FormControl>
  //   <RadioGroup
  //     aria-labelledby="demo-radio-buttons-group-label"
  //     value={group}
  //     row
  //     name="radio-buttons-group"
  //     onChange={(event) => {
  //       const { value } = event.target;
  //       console.log("value: ", value);
  //       onGroupChange(value);
  //       setGroup(value);
  //       // selectedGroup = value;
  //     }}
  //   >
  //     {
  //       groupList.map((group) => {
  //         return <FormControlLabel key={group} value={group} control={<Radio />} label={group} />

  //       })
  //     }
  //   </RadioGroup>
  // </FormControl>);

}


function MemberSelection({ memberList, onMemberChange }) {
  const [memberID, setMemberID] = useState(null);

  return (<FormControl>
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      value={memberID}
      row
      name="radio-buttons-group"
      onChange={(event) => {
        const { value } = event.target;
        console.log("value: ", value);
        onMemberChange(value);
        setMemberID(value);
        // selectedGroup = value;
      }}
    >
      {
        memberList.map((member) => {
          return <FormControlLabel key={member.id}
            value={member.id} control={<Radio />}
            label={member.display_name} />

        })
      }
    </RadioGroup>
  </FormControl>);
}



export default function GroupMemberSelection({ memberList, onSelectMember }) {
  const [selectedGroup, setSelectedGroup] = useState("");
  let groupList = getGroupList(memberList);


  const filteredMemberList = memberList.filter((member) => {
    return member.group === selectedGroup;
  });

  const handleSelectMember = (memberID) => {
    console.debug("memberID", memberID);
    // onSelectMember(memberID);
    let member = memberList.find((item) => {
      return item.id == memberID;
    });
    // for (let member of memberList) {
    //   if (member.id == memberID) {
    if (onSelectMember !== undefined) {
      console.debug("member", member);
      onSelectMember(member);
    }
    //   break;
    // }
  }
  // const findMember = memberList.find((member) => {
  //   console.debug("member.id", member.id, " memberID", memberID);
  //   return member.id === memberID;
  // });
  // console.debug("findMember", findMember);

  // if(onSelectMember !== undefined) {
  //   onSelectMember(findMember);
  // }


  // let group = groupList?.length === 0 ? null : groupList[0];
  // setSelectedGroup(group);
  // let selectedGroup = groupList?.length === 0 ? null : groupList[0];

  return <Card sx={{ p: 2 }}>
    <Stack spacing={1} direction="column">
      <GroupSelection groupList={groupList} onGroupChange={(group) => {
        console.debug("group", group);
        setSelectedGroup(group);
        // 
        // setSelectedGroup("2A_a"); 
      }} />
      <MemberSelection memberList={filteredMemberList} onMemberChange={(memberID) => {
        console.debug("memberID", memberID);
        handleSelectMember(memberID);

      }} />
    </Stack>
  </Card>
}

