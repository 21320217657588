import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import MockData from "../../helper/MockData";
import OverallSportDisplay from "../../components/leaderBoard/OverallSportDisplay";
import MosaicSportDisplay from "../../components/leaderBoard/MosaicSportDisplay";
import MosaicSportDisplayItem from "../../components/leaderBoard/MosaicSportDisplayItem";


export const getCurrentDateStr = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    if (day < 10) {
        return `${year}-${month}-0${day}`;
    }
    return `${year}-${month}-${day}`;
}

export default function TestSimple() {
    const { t } = useTranslation();

    let lang = getLang();
    const leaderboard = MockData.getSportLeaderboard();
    const eventId = 1;
    const todayStr = getCurrentDateStr(); // new Date().toISOString().split("T")[0];

    const rank1Board = leaderboard.result[0];
    const rank5Board = leaderboard.result[5];
    // console.debug("leaderboard=", leaderboard);
    // console.debug("rank1Board=", rank1Board);
    // console.debug("rank5Board=", rank5Board);

    return (
        <Container maxWidth="lg">
            <Stack direction="column" sx={{background: 'black'}}>
                <Typography variant="h3">Test Overall Sport Display</Typography>


                <Box>
                    {/* <pre>
                        {JSON.stringify(leaderboard, null, 2)}
                    </pre> */}
                </Box>
                {/* <OverallSportDisplay
                    SportLeader={leaderboard}
                    eventID={eventId}
                    date={todayStr}

                /> */}
                <MosaicSportDisplayItem content={rank1Board} />
                <MosaicSportDisplayItem content={rank5Board} itemSize="normal" />
                <MosaicSportDisplay
                    leaderboard={leaderboard}
                    eventID={eventId}
                    date={todayStr}

                />
            </Stack>
        </Container>
    )
}
