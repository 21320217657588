import { Box, Grid } from "@mui/material";

import SponsorLogos from "../../app/SponsorLogos";

export default function Footer() {
  return (
    <Box
      position={"fixed"}
      bottom={0}
      zIndex={10}
      width={"100vw"}
      height={"70px"}
      bgcolor="white"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        px={3}
      >
        {/* <Box
          component={"img"}
          src={"/logo.png"}
          sx={{ objectFit: "contain", width: "60px", height: "100%" }}
        /> */}
        {/* <SponsorLogos /> */}
        {/* <Box
          component={"img"}
          src={"/bfcf_badge.png"}
          sx={{ objectFit: "contain", width: "200px", height: "100%" }}
        /> */}
      </Grid>
    </Box>
  );
}
