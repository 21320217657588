import { Info } from "@mui/icons-material";
import {
    Container,
    Typography,
    Box,
    Button,
    Stack,
} from "@mui/material";
import { ca } from "date-fns/locale";
import React from 'react';
import { useState } from 'react';

import { AdminApi } from "../../api/AdminApi";
import { InfoApi } from "../../api/InfoApi";
import { StatApi } from "../../api/StatApi";


export default function TestApi() {
    // const { t } = useTranslation();
    const [file, setFile] = useState(null);

    // getMemberListByOrg
    const getMemberListByOrg = async () => {
        let orgID = 2;
        let result = await InfoApi.getMemberListByOrg(orgID);
        console.debug("result: ", result);
    }

    //getOrgnizationListCallback
    const getOrgnizationListCallback = async () => {
        let callback = (isSuccess, msg, data) => {
            console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
        }

        AdminApi.getOrgnizationListCallback(callback);
    }


    const getOrgnizationDetailCallback = async () => {
        let orgID = 1;
        let callback = (isSuccess, msg, data) => {
            console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
        }

        AdminApi.getOrgnizationDetailCallback(orgID, callback);
    }

    // const loadEventLeaderboardCallback = async (
    //     eventID,
    //     date,
    //     timeslotID,
    //     callback
    //   ) => {

    const loadEventLeaderboardCallback = async () => {
        let eventID = 14;
        // let date = "2021-09-01";
        let date = "2023-06-03";
        let timeslotID = 0;
        let callback = (result) => {
            console.debug("result: ", result);
        }
        await AdminApi.loadEventLeaderboardCallback(
            eventID, date, timeslotID, callback);
    }

    const queryMembers = async () => {
        const filters = [
            { 'field': 'user_type', 'value': 'school', 'op': 'eq' }
        ];

        const result = await InfoApi.queryMembers(1, -1, filters);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }


    const testSubmitScoreQRCode = async () => {
        let eventID = 3;
        let qrCode = "BCDPMCF";
        let skillCode = 'vjump';
        let skillScore = 33;

        // const submitScoreQRCode = async (eventID, qrCode, skillCode, skillScore) => {
        const result = await AdminApi.submitScoreQRCode(eventID, qrCode, skillCode, skillScore);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }

    const testLogin = async () => {
        let account = "mosaic";
        let password = "Abcd##1234";

        const result = await AdminApi.login(account, password);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }
    //getUserEventList

    const testGetUserEventList = async () => {
        // const userID = 5;
        const userID = 1;
        const result = await InfoApi.getUserEventList(userID);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }

    const testEventList = async () => {

        const result = await InfoApi.getEventList();
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }


    const testSkillList = async () => {

        const result = await InfoApi.getSkillList();
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }

    const testSkillDetail = async () => {

        const result = await InfoApi.getSkillDetail(1);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }


    const testSubmitScore = async () => {
        console.debug("onLogin: clicked");
        const memberID = 4;
        const eventID = 14;
        const skill = 'situp';
        const score = 60;

        const result = await AdminApi.submitScore(memberID, eventID, skill, score);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }

    const testCheckSkillInput = async () => {
        console.debug("onLogin: clicked");
        const eventID = 1;
        // const accessID = 'BCBJ';
        const qrcodeID = 'KENLKEN';
        const skill = 'situp';
        const score = 60;

        const result = await AdminApi.checkSkillInput(eventID, qrcodeID, skill, score);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }


    const loadEventOverallLeaderboard = () => {
        console.debug("onLogin: clicked");
        const eventID = 1;
        const date = '2022-12-08';
        // const timeslot = 3;
        // const skillCode = 'situp';

        AdminApi.loadEventOverallLeaderboardCallback(
            eventID, date, (result) => {
                console.debug("result: ", result);
            });

    }

    const loadSingleEventOverallLeaderboard = () => {
        console.debug("onLogin: clicked");
        const eventID = 1;
        const date = '2022-12-08';
        const queryCode = 'vjump_all';
        // const timeslot = 3;
        // const skillCode = 'situp';

        AdminApi.loadSingleEventOverallLeaderboardCallback(
            eventID, date, queryCode, (result) => {
                console.debug("result: ", result);
            });

    }

    //loadSingleEventLeaderboardCallback
    const loadSingleEventLeaderboard = () => {
        console.debug("onLogin: clicked");
        const eventID = 1;
        const date = '2022-12-07';
        const timeslot = 3;
        const skillCode = 'situp';

        AdminApi.loadSingleEventLeaderboardCallback(
            eventID, date, timeslot, skillCode, (result) => {
                console.debug("result: ", result);
            });

    }


    const loadEventLeaderboard = async () => {
        console.debug("onLogin: clicked");
        const eventID = 1;
        const date = '2022-12-08';
        const timeslot = 9;
        const showDetail = true;

        const result = await AdminApi.loadEventLeaderboard(eventID, date, timeslot, showDetail);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }

    }


    const previewHealthData = async () => {
        console.debug("onLogin: clicked");
        const eventID = 1;
        // const date = '2022-12-02';
        // const timeslot = 6;

        if (file === null) {
            alert(
                "Please select a file"
            )
            return;
        }

        try {
            const result = await AdminApi.previewHealthData(eventID, file);
            console.debug("result: ", result);
            if (result) {
                alert("Success");
            }

        } catch (error) {
            alert(
                error.message
            )
        }

    }


    const importHealthData = async () => {
        console.debug("importHealthData: clicked");
        const eventID = 1;
        // const date = '2022-12-02';
        // const timeslot = 6;

        if (file === null) {
            alert(
                "Please select a file"
            )
            return;
        }

        try {
            const result = await AdminApi.importHealthData(eventID, file);
            console.debug("result: ", result);
            if (result) {
                alert("Success");
            }

        } catch (error) {
            alert(
                error.message
            )
        }

    }
    //previewHealthData

    const getEventStat = async () => {
        console.debug("getEventStat: clicked");
        const eventID = 14;
        const date = '';

        const result = await StatApi.getEventStat(eventID, date);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }
    // queryMemberSkillData
    const queryMemberSkillData = async () => {
        console.debug("queryMembers: clicked");
        const page = 1;
        const pageSize = 50;
        // {'field':'display_name', 'value':"ken", 'op':'like'},            
        // 
        let filters = [
            // { 'field': 'display_name', 'value': "ken", 'op': 'like' },
            { 'field': 'skill_code', 'value': "moveit_ai_punch", 'op': 'eq' }
        ];
        // 

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        const result = await InfoApi.queryMemberSkillData(
            page, pageSize, filters, 'default');
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }


    const exportMemberSkillData = async () => {
        console.debug("exportMemberSkillData: clicked");
        // 
        let filters = [
            // { 'field': 'display_name', 'value': "ken", 'op': 'like' },
            { 'field': 'skill_code', 'value': "moveit_ai_punch", 'op': 'eq' }
        ];
        // 

        // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
        const result = await InfoApi.exportMemberSkillData(
            filters);
        console.debug("result: ", result);
        if (result) {
            alert("Success");
        }
    }
    // exportMemberSkillData


    console.debug("testSubmitScore: ", testSubmitScore.name);

    const testList = [
        getMemberListByOrg,
        testGetUserEventList,
        exportMemberSkillData,
        queryMemberSkillData,
        getEventStat,
        getOrgnizationListCallback,
        loadEventLeaderboardCallback,
        getOrgnizationDetailCallback,
        queryMembers,
        testLogin,
        loadEventOverallLeaderboard,
        loadSingleEventLeaderboard,
        loadSingleEventOverallLeaderboard,
        importHealthData,
        previewHealthData,
        loadEventLeaderboard,
        testSubmitScoreQRCode,
        testEventList,
        testSkillList,
        testSkillDetail,
        testSubmitScore,
        testCheckSkillInput,

    ];

    const handleFileChange = (e) => {
        const target = e.target;
        const files = target.files;
        console.debug("files=", files);
        if (files && files.length > 0) {
            setFile(files[0]);
        }
    }

    const fileBox = file ? (
        <Box>{file.name}</Box>
    ) : (
        <Box>no file selected</Box>
    );



    return (
        <Container maxWidth="lg">
            <Typography variant="h3">Test TestApi</Typography>

            <Stack direction="row" gap={3} mb={4}  >
                <Button
                    variant="contained"
                    component="label"
                >
                    Upload File
                    <input
                        type="file"
                        hidden
                        onChange={handleFileChange}
                    />
                </Button>
                {fileBox}
            </Stack>

            <Box display="flex" direction="column" gap={3} sx={{ flexWrap: 'wrap' }}>
                {
                    testList.map((testFunc, index) => {
                        return (
                            <Button key={index} variant="outlined" color="primary"
                                onClick={
                                    testFunc
                                }>
                                {testFunc.name}
                            </Button>
                        );
                    })
                }
            </Box>
        </Container>
    )
}
