import _axios from "axios";
import ReactSwal from "./AlertHelper";
import {
  getLocalStorageUser,
  removeLocalStorageUser,
} from "./LocalStorageHelper";
import { Translation } from "react-i18next";
import { cos } from "numeric";

function getToken() {
  try {
    const userInfo = getLocalStorageUser();
    const user = JSON.parse(userInfo);
    return user.jwt;
  } catch (error) {
    return "";
  }
}

const axios = () => {
  const token = getToken();

  console.debug("env=", process.env.REACT_APP_API_SERVER);

  const instance = _axios.create({
    baseURL: process.env.REACT_APP_API_SERVER,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: 2 * 60 * 100,
  });

  instance.interceptors.response.use(
    function (response) {
      if (!response.data.success) {
        ReactSwal.fire({
          title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
          html: response.data.message || "Unknown error",
        });
        return Promise.reject(response.data.message || "Unknown error");
      }
      return response;
    },
    function (error) {
      // cosole.error("error=", error);
      const statusCode = error.response.status;

      if (statusCode == 404) {
        ReactSwal.fire({
          title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
          html: "404 Not Found",
        });
        return Promise.reject(error);
      }

      if (statusCode == 401) {
        // no permission or token expired
        removeLocalStorageUser();
        ReactSwal.fire({
          title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
          html: error.response.data.error || "Expired",
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            window.location.href = "/login";
          }
        });
      }

      ReactSwal.fire({
        title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
        html: error.response.data.error || error.message,
      });

      return Promise.reject(error);
    }
  );

  return instance;
};

const getAbortController = () => {
  if (typeof window === "undefined") return;
  return new AbortController();
};

const formatResponse = (response) => {
  if (response === undefined || response == null) {
    return null;
  }

  const responseData = response.data;
  if (responseData === undefined || responseData == null) {
    return null;
  }

  return responseData.result;
};
export function formatQueryResponse(response) {
  let { total, page, page_size, data, result } = response;
  if (result !== undefined) {
    return {
      data: result,
      pagination: {
        total,
        page,
        page_size,
      },
    };
  }
  return {
    data,
    pagination: {
      total,
      page,
      page_size,
    },
  };
}

export async function getDataRequest(url, callback = (isSuccess, data) => {}) {
  const controller = getAbortController();
  let option = { signal: controller?.signal };

  await axios()
    .get(url, option)
    .then(function (response) {
      //helper function to clean up the data
      let date = formatResponse(response);
      return callback(true, date);
    })
    .catch(function (thrown) {
      if (thrown) {
        console.warn("System Error : ", thrown);
        return callback(false, thrown.response);
      }
    });

  return controller?.abort() || null;
}

export async function postDataRequest(url, data = {}, callback = () => {}) {
  const controller = new window.AbortController();
  await axios()
    .post(url, data, { signal: controller.signal })
    .then(function (response) {
      //helper function to clean up the data
      // let date = formatResponse(response);
      callback(true, response.message);
    })
    .catch(function (thrown) {
      if (thrown) {
        console.warn("System Error : ", thrown);
        callback(false, thrown.response.message);
      }
    });
  return controller.abort();
  //return response.data;
}

export default axios();
