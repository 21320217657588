import {
    Container,
    Typography,
    Box,
    Dialog,
    Card,
    Button,
    CardContent,
    CardHeader,
} from "@mui/material";
import { minWidth } from "@mui/system";
import React from 'react';
import { useState } from "react";
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import ConfirmInputDialog from "../../components/app/ConfirmInputDialog";
import MockData from "../../helper/MockData";
import { AdminApi } from "../../api/AdminApi";


export default function TestDialog() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [confirmResult, setConfirmResult] = useState(MockData.checkInputResult());

    let lang = getLang();

    // setConfirmResult(MockData.checkInputResult());

    // use

    // const memberSkillInfo = MockData.getMemberEventInfo().skillInfoList[0];


    const callCheckInput = async () => {
        const eventID = 1;
        const qrCodeID = 'BCDPMCF';
        const skill = 'situp';
        const score = '33';


        const result = await AdminApi.checkSkillInput(eventID, qrCodeID, skill, score);
        console.debug("result=", result);
        setConfirmResult(result);

        setOpen(true);
    }

    // reference 
    //  
    return (
        <Container maxWidth="lg">
            <Typography variant="h3">Test Dialog</Typography>

            <div>
                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
            </div>

            <Button variant="contained" onClick={() => setOpen(true)}>Open Dialog</Button>


            <Button variant="contained" sx={{ml:3}} onClick={callCheckInput}>Call API & Open Dialog</Button>

            <ConfirmInputDialog confirmResult={confirmResult}
                open={open}
                onClose={() => setOpen(false)}
            />


            <Dialog open={false} fullWidth>
                <Card>
                    <CardHeader title="確定你的資料" action="關閉" />
                    <CardContent>
                        <Box gap={3} pb={3}>
                            <Typography variant="h4" sx={{ width: '100%' }}>
                                參與者名字：XXX
                            </Typography>
                            <Typography variant="body1" sx={{ width: '100%' }}>
                                電話號碼：9123****
                            </Typography>
                        </Box>
                        <Box gap={3} pb={3}>
                            <Typography variant="h4" sx={{ width: '100%' }}>
                                Rank：3
                            </Typography>
                            <Typography variant="h6" sx={{ width: '100%' }}>
                                Score: 23
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Dialog>
        </Container>
    )
}
