import { Typography, Box, Button, FormControl, Stack, MenuItem, InputLabel, Select } from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import MockData from "../../helper/MockData";
import { InfoApi } from "../../api/InfoApi";
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import { AdminApi } from "../../api/AdminApi";
import AdminLeaderboardCard from "../../components/app/AdminLeaderboardCard";
import EventList from "../../components/app/EventList";
import { getCurrentDateStr } from "../../helper/StringHelper";
import { dateValueToYYYYMMDD } from "../../helper/TimeHelper";
import EventLeaderboardQueryForm from "../../components/adminLeaderboard/EventLeaderboardQueryForm";

function getLeadboardTable(leaderboard, selectedSkill) {
  if (leaderboard == null) {
    return <Box>Not Ready</Box>;
  }

  const leaderboardItems = leaderboard.map((item) => {
    if(selectedSkill !== 0 && item.id !== selectedSkill) {
      return <></>;
    }
    return <AdminLeaderboardCard key={item.id} leaderboardItem={item} />
  });


  return <Stack dirction="column">
    {leaderboardItems}
    {/* <Box>Leaderboard</Box>
    <pre>
      {JSON.stringify(leaderboard, null, 2)}
    </pre> */}
  </Stack>
}

export default function AdminLeadboardSection({mode="admin", userID}) {
  const [eventList, setEventList] = useState([]);
  const [event, setEvent] = useState(null);
  const [timeslot, setTimeslot] = useState(1);
  const [skillID, setSkillID] = useState(0);
  const [leaderboard, setLeaderboard] = useState(null);
  const { t } = useTranslation();

  // 
  const isAdminUser = mode === "admin";
  userID = userID || 0;

  let lang = getLang();

  useEffect(() => {
    if(isAdminUser) {
      loadAllEventList();
    } else {
      loadUserEventList();
    }
    
  }, []);

  const onTimeslotChanged = (event) => {
    setTimeslot(event.target.value);
  };

  const onQueryLeaderboard = async (inputData) => {
    console.debug("onQueryLeaderboard: ", inputData);

    const eventID = inputData.eventID || 0;
    const timeslotID = inputData.timeslotID || 0;
    const skillID = inputData.skillID || 0;
    const selectedDate = inputData.selectedDate || null;

    const selectedDateStr = selectedDate ? dateValueToYYYYMMDD(selectedDate) : "";
  
    console.debug("selectedDateStr: ", selectedDateStr);

    const result = await AdminApi.loadEventOverallLeaderboard(
                eventID, selectedDateStr);
    console.debug("result: ", result);
    if (result) {
      setSkillID(skillID);
      setLeaderboard(result);
      // alert("Success");
    } else {
      alert("Failed to load leaderboard");
    }
  };

  const onQuery = async () => {
    // alert('query');

    // ken: need to fix 
    const eventID = process.env.REACT_APP_LEADERBOARD_EVENT || 4;
    // const eventID = 3;
    const date = getCurrentDateStr();

    const result = await AdminApi.loadEventLeaderboard(eventID, date, timeslot, true);
    console.debug("result: ", result);
    if (result) {
      setLeaderboard(result);
      // alert("Success");
    } else {
      alert("Failed to load leaderboard");
    }
  }

  const updateEventList = (newEventList) => {
    setEventList(newEventList);
    if (newEventList.length === 0) {
      setEvent(null);
    } else {
      setEvent(newEventList[0]);
    }
  }
  
  const loadUserEventList = async () => {
    // console.debug("currentMemberID: ", currentMemberID);
    const result = await InfoApi.getUserEventList(userID);
    console.debug("result: ", result);
    updateEventList(result);

  };



  const loadAllEventList = async () => {
    // console.debug("currentMemberID: ", currentMemberID);
    const result = await InfoApi.getEventList();
    console.debug("result: ", result);
    updateEventList(result);
    // setEventList(result);
  };

  if (!event) {
    return <Box>Loading...</Box>;
  }

  const selectionItems = event.timeslotList.map((timeslot) => {
    return <MenuItem value={timeslot.id}>{timeslot.timeslot_caption}</MenuItem>;
  });

  //<MenuItem value={timeslot.id}>{timeslot.timeslot_caption}</MenuItem>

  const selection = <FormControl fullWidth>
    <InputLabel id="eventID-label">Timeslot</InputLabel>
    <Select
      labelId="timeslot-label"
      id="timeslot_id"
      value={timeslot}
      label="Event"
      onChange={onTimeslotChanged}
    >
      {selectionItems}
    </Select>
  </FormControl>;

  const leaderboardTable = getLeadboardTable(leaderboard, skillID);


  return (
    <Stack direction="column" spacing={2}>
      <EventLeaderboardQueryForm eventList={eventList} onQuery={onQueryLeaderboard}>

      </EventLeaderboardQueryForm>

      {leaderboardTable}
      <Box>
        <pre>
          {/* {JSON.stringify(eventList, null, 2)} */}

          {/* {JSON.stringify(leaderboard, null, 2)} */}
          {/* {JSON.stringify(event, null, 2)} */}
        </pre>
      </Box>

    </Stack>
  );
}
