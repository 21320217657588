import PersonIcon from "@mui/icons-material/Person";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const mainField = "id";

const dataFieldConfig = [
    {
        id: "created_at",
        field: "created_at",
        align: "center",
        disablePadding: false,
        label: "參與者時間",
        fieldAction: () => { },
    },
    {
        id: "display_name",
        field: "display_name",
        align: "center",
        disablePadding: false,
        label: "參與者名字",
        fieldAction: () => { },
    },
    {
        id: "member_id",
        field: "member_id",
        align: "center",
        disablePadding: false,
        label: "ECLASS編號",
        fieldAction: () => { },
    },
    {
        id: "group",
        field: "group",
        align: "center",
        disablePadding: false,
        label: "組別/班別",
        fieldAction: () => { },
    },
    {
        id: "group_num",
        field: "group_num",
        align: "center",
        disablePadding: false,
        label: "編號/班號",
        fieldAction: () => { },
    },
    {
        id: "skill_code",
        field: "skill_code",
        align: "center",
        disablePadding: false,
        label: "技能編號",
        fieldAction: () => { },
    },
    {
        id: "skill_name_zh",
        field: "skill_name_zh",
        align: "center",
        disablePadding: false,
        label: "技能名字",
        fieldAction: () => { },
    },
    {
        id: "skill_score",
        field: "skill_score",
        align: "center",
        disablePadding: false,
        label: "成績",
    },
    {
        id: "skill_unit",
        field: "skill_unit",
        align: "center",
        disablePadding: false,
        label: "單位",
    },
];

const fetchDataConfig = {
    // url: "/organization/query",
    url: "/memberSkillData/query",
    // fixedFilter: [{ field: "name_en,name_zh", value: '', op: "like" }],
    // extraQuery: [{ key: "org", field: "orgId" }],
    // fixedFilter: [],
};

const tableActionConfig = [

];

const filterConfig = [
];

const TableConfig = {
    mainField,
    dataFieldConfig,
    fetchDataConfig,
    tableActionConfig,
    filterConfig,
};

export default TableConfig;
